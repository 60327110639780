import {get} from "axios"
import {getErrorMessage} from "../../../services/api"
import {REQUEST, REQUEST_SUCCESS, REQUEST_FAILURE} from "../../../store/requests/requestsReducer"
import {ENVIRONMENT_ENDPOINTS} from "../../../constants/endpoints"
import {RequestInfo, RequestKeys} from "../../../constants/requests"

export const getBeneficiaryUrl = (fromEBS, requestKey) => dispatch => {
    dispatch({
        type: REQUEST,
        requestKeyName: fromEBS
            ? requestKey.GET_ESPACE_BENEFICIARY_URL
            : RequestInfo[requestKey.GET_ESPACE_BENEFICIARY_URL].name,
    })
    return get(ENVIRONMENT_ENDPOINTS.beneficiaryUrl).then(
        response => {
            dispatch({
                type: REQUEST_SUCCESS,
                requestKeyName: fromEBS
                    ? requestKey.GET_ESPACE_BENEFICIARY_URL
                    : RequestInfo[requestKey.GET_ESPACE_BENEFICIARY_URL].name,
                payload: response.data,
            })

            return Promise.resolve(response.data)
        },
        error => {
            dispatch({
                type: REQUEST_FAILURE,
                requestKeyName: fromEBS
                    ? requestKey.GET_ESPACE_BENEFICIARY_URL
                    : RequestInfo[requestKey.GET_ESPACE_BENEFICIARY_URL].name,
                message: getErrorMessage(error),
            })

            return Promise.reject()
        },
    )
}

export const getBeneficiaryRegistrationUrl = contractNumber => dispatch => {
    dispatch({
        type: REQUEST,
        requestKeyName: RequestInfo[RequestKeys.GET_ESPACE_BENEFICIARY_REGISTRATION_URL].name,
    })

    const params = {
        contractNumber,
    }

    return get(ENVIRONMENT_ENDPOINTS.beneficiaryRegistrationUrl, {params}).then(
        response => {
            dispatch({
                type: REQUEST_SUCCESS,
                requestKeyName: RequestInfo[RequestKeys.GET_ESPACE_BENEFICIARY_REGISTRATION_URL].name,
                payload: response.data,
            })

            return Promise.resolve(response.data)
        },
        error => {
            dispatch({
                type: REQUEST_FAILURE,
                requestKeyName: RequestInfo[RequestKeys.GET_ESPACE_BENEFICIARY_REGISTRATION_URL].name,
                message: getErrorMessage(error),
            })

            return Promise.reject()
        },
    )
}

export const getBeneficiaryLoginUrl = () => dispatch => {
    dispatch({
        type: REQUEST,
        requestKeyName: RequestInfo[RequestKeys.GET_ESPACE_BENEFICIARY_LOGIN_URL].name,
    })

    return get(ENVIRONMENT_ENDPOINTS.beneficiaryLoginUrl).then(
        response => {
            dispatch({
                type: REQUEST_SUCCESS,
                requestKeyName: RequestInfo[RequestKeys.GET_ESPACE_BENEFICIARY_LOGIN_URL].name,
                payload: response.data,
            })

            return Promise.resolve(response.data)
        },
        error => {
            dispatch({
                type: REQUEST_FAILURE,
                requestKeyName: RequestInfo[RequestKeys.GET_ESPACE_BENEFICIARY_LOGIN_URL].name,
                message: getErrorMessage(error),
            })

            return Promise.reject()
        },
    )
}

export const getBeneficiaryServiceUrl = (fromEBS, requestKey) => dispatch => {
    dispatch({
        type: REQUEST,
        requestKeyName: !fromEBS
            ? RequestInfo[requestKey.GET_ESPACE_BENEFICIARY_SERVICE_URL].name
            : requestKey.GET_ESPACE_BENEFICIARY_SERVICE_URL,
    })

    return get(ENVIRONMENT_ENDPOINTS.beneficiaryServiceUrl).then(
        response => {
            dispatch({
                type: REQUEST_SUCCESS,
                requestKeyName: !fromEBS
                    ? RequestInfo[requestKey.GET_ESPACE_BENEFICIARY_SERVICE_URL].name
                    : requestKey.GET_ESPACE_BENEFICIARY_SERVICE_URL,
                payload: response.data,
            })

            return Promise.resolve(response.data)
        },
        error => {
            dispatch({
                type: REQUEST_FAILURE,
                requestKeyName: !fromEBS
                    ? RequestInfo[requestKey.GET_ESPACE_BENEFICIARY_SERVICE_URL].name
                    : requestKey.GET_ESPACE_BENEFICIARY_SERVICE_URL,
                message: getErrorMessage(error),
            })

            return Promise.reject()
        },
    )
}
