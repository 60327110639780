import React from "react"

import PropTypes from "prop-types"

import "./link.scss"

export const LINK_VARIANTS = {DEFAULT: "default", DARK: "dark", LIGHT: "light"}

/**
 * A link component.
 */
const Link = ({children, disabled, onClick, className, variant}) => (
    <span
        className={`link-component variant-${variant}${className ? " " + className : ""}${disabled ? " disabled" : ""}`}
        onClick={disabled ? () => "" : onClick}
    >
        {children}
    </span>
)

Link.propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    variant: PropTypes.oneOf(Object.values(LINK_VARIANTS)),
}

Link.defaultProps = {
    className: null,
    disabled: false,
    variant: LINK_VARIANTS.DEFAULT,
}

export default Link
