/**
 * Beneficiary area visualisation mode
 * This drives which menu is available
 * and some others functional rules
 */
export const ModesVisualisation = {
    CESU_RH: "CESU_RH",
    CESU_SOCIAL: "CESU_SOCIAL",
    EB_UNIQUE: "EB_UNIQUE",
    EB_SAP: "EB_SAP",
}
