import PropTypes from "prop-types"
import React from "react"
import {connect, Provider} from "react-redux"
import {Route, Router, Switch} from "react-router-dom"
import {Page} from "./constants/page/pages"
import {PagesArchitecture} from "./constants/page/pagesArchitecture"
import history from "./history"
import {AsyncTechnicalErrorScene} from "./layouts/asyncScenes"
import MainLayout from "./layouts/mainLayout"
import ErrorBoundaryContainer from "./shared-components/components/ErrorBoundary/errorBoundaryContainer"
import {MatomoTracker} from "./shared-components/components/MatomoTracker/matomoTracker"
import StyleLoaderContainer from "./shared-components/components/StyleLoader/styleLoaderContainer"
import TextsProvider from "./shared-components/components/TextsProvider/textsProvider"
import {BENEFICIARY_AREA} from "./shared-components/constants/areaTypes"
import injectTexts from "./shared-components/styles/injectTexts"
import {getCookiesChoices} from "./store/selectors/localStorage/cookiesSelectors"
import {getDynamicConfigurationValue} from "./store/selectors/localStorage/dynamicConfigurationSelectors"
import {areCustomizedStyle, getStyle} from "./store/selectors/localStorage/styleSelectors"
import {getTexts, isCustomizedTexts} from "./store/selectors/localStorage/textSelector"
import {isSmallClient, isSocialContract} from "./store/selectors/sessionStorage/userSelectors"
import texts, {defaultTextsSmallClient, defaultTextsSocialContract} from "./styles/globalText"
import {FullScreenLoader} from "./shared-components/components/FullScreenLoader/fullScreenLoader"

const shouldOverrideDefaultTexts = state => isSmallClient(state) || isSocialContract(state)

const AsyncTechnicalError = AsyncTechnicalErrorScene

/**
 * Root of the application.
 * Set the provider to distribute the store in all components.
 * Set the router root.
 */
const Root = ({isSmallClient, isSocialContract, store, texts}) => (
    <Provider store={store}>
        <MatomoTracker getCookiesChoices={getCookiesChoices}>
            <TextsProvider texts={texts}>
                <Router history={history}>
                    <Switch>
                        <Route
                            component={AsyncTechnicalError}
                            exact
                            path={PagesArchitecture[Page.TECHNICAL_ERROR].route}
                        />
                        <StyleLoaderContainer
                            espaceType={BENEFICIARY_AREA}
                            getDynamicConfigurationValue={getDynamicConfigurationValue}
                            getStyle={getStyle}
                            getTexts={getTexts}
                            isCustomizedStyle={areCustomizedStyle}
                            isCustomizedTexts={isCustomizedTexts}
                            overrideDefaultTexts={
                                isSmallClient
                                    ? defaultTextsSmallClient
                                    : isSocialContract
                                    ? defaultTextsSocialContract
                                    : {}
                            }
                        >
                            <FullScreenLoader />
                            <ErrorBoundaryContainer routeToRedirect={PagesArchitecture[Page.HOME].route}>
                                <Route component={MainLayout} path="" />
                            </ErrorBoundaryContainer>
                        </StyleLoaderContainer>
                    </Switch>
                </Router>
            </TextsProvider>
        </MatomoTracker>
    </Provider>
)

Root.propTypes = {
    store: PropTypes.object.isRequired,
    texts: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    isSmallClient: isSmallClient(state),
    isSocialContract: isSocialContract(state),
})

export default injectTexts(texts, connect(mapStateToProps)(Root))
