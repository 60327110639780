import {Page} from "./pages"

export const PagesArchitectureMenuEbUnique = {
    [Page.COMMANDE]: {
        breadcrumbText: "Commandes",
        route: "/commandes/choix-dispositif",
        userConnected: true,
    },
    [Page.COMMANDE_CHOIX_DISPOSITIF]: {
        breadcrumbText: "Nouvelle commande",
        route: "/commandes/choix-dispositif",
        userConnected: true,
    },
    [Page.COMMANDE_SUIVI]: {
        breadcrumbText: "Historique",
        route: "/commandes",
        userConnected: true,
    },
}
