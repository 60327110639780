import React, { Component } from "react"

import { startsWith } from "lodash"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Route, Switch, withRouter } from "react-router-dom"

import { Order } from "../constants/orderActionsEnum"
import { Page } from "../constants/page/pages"
import { PagesArchitecture } from "../constants/page/pagesArchitecture"
import BypassLoginComponentEBUnique from "../scenes/BypassLoginComponentEBUnique"
import { saveCookiesChoices } from "../shared-components/actions/localStorage/cookies/cookiesActions"
import { disconnectUser } from "../shared-components/actions/sessionStorage/user/userActions"
import { DISPLAY_FULLSCREEN_LOADER } from "../shared-components/actions/sessionStorage/user/userActionTypes"
import { setResponsive } from "../shared-components/actions/temporaries/menu/menuAction"
import { logoutUser } from "../shared-components/common/actions/user/userActions"
import CheckBrowserContainer from "../shared-components/components/CheckBrowser/checkBrowserContainer"
import CookiesNotificationContainer from "../shared-components/components/CookiesNotification/cookiesNotificationContainer"
import NotificationsContainer from "../shared-components/components/Notifications/notificationsContainer"
import { DOMISERVE_CONTACT_URL } from "../shared-components/constants/links"
import NotificationsLayout from "../shared-components/layouts/notificationsLayout"
import { wasAuthenticationBypassed } from "../store/selectors/localStorage/bypassedAuthenticationSelectors"
import { getCesuRhContracts, getCesuSocialContracts, isContractWittholdingAllowed } from "../store/selectors/localStorage/contractsSelectors"
import { getCookiesChoices, getModalStep, isCookiesModalOpened, isCookiesUserAcceptanceExpired, isUserAcceptedCookies } from "../store/selectors/localStorage/cookiesSelectors"
import { getCESURHMessage, getEnabledFeatures, getNotificationWithFeature } from "../store/selectors/localStorage/featuresSelectors"
import { getOrderType } from "../store/selectors/localStorage/orderTypeSelectors"
import { getSelectedContract, getVisualisationMode } from "../store/selectors/localStorage/visualisationSelectors"
import { getNotifications } from "../store/selectors/notifications/notificationsSelectors"
import { getUserFullname, getUserPermissions, isFromBackOffice, isReadOnly, isUserConnected } from "../store/selectors/sessionStorage/userSelectors"
import { isMenuResponsive } from "../store/selectors/temporaries/menuSelectors"
import { getActualPage } from "../store/selectors/temporaries/pageSelectors"
import { MatomoTrackPageView } from "./matomoTrackPageView"
import RoutingLayout from "./routingLayout"

class MainLayout extends Component {
    componentDidMount() {
        this.checkVisualisationMode(this.props.actualPage)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.actualPage !== this.props.actualPage) {
            this.checkVisualisationMode(nextProps.actualPage)
            this.checkBackOffice(nextProps.actualPage)
            this.checkBypassLogin(nextProps.actualPage)
            this.checkReadOnly(nextProps.actualPage)
            this.initializeLoader(nextProps.actualPage)
        }
    }

    /**
     * Visualisation mode check
     */
    checkVisualisationMode = actualPage => {
        // Check if user is logged in without bypassing login and has not selected any visualisation mode
        if (this.props.isUserConnected && !this.props.wasAuthenticationBypassed && !this.props.visualisationMode) {
            // Force the user to go to VISUALISATION_CHOICE page
            // This is mandatory because the user could access any pages by URL otherwise
            this.props.history.push(PagesArchitecture[Page.VISUALISATION_CHOICE].route)
        }

        if (this.props.isUserConnected && this.props.visualisationMode) {
            const visualisationModes = PagesArchitecture[actualPage].visualisationModes
            if (visualisationModes && !visualisationModes.includes(this.props.visualisationMode)) {
                this.props.history.push(PagesArchitecture[Page.UNAUTHORIZED].route)
            }
        }
    }

    checkBackOffice = actualPage => {
        if (
            this.props.isFromBackOffice &&
            !startsWith(actualPage, "MILLESIME") &&
            !startsWith(actualPage, "VISUALISATION_CHOICE") &&
            !startsWith(actualPage, "HOME")
        ) {
            this.props.history.push(PagesArchitecture[Page.MILLESIME_TRADE_CHOICE].route)
        }
    }

    checkBypassLogin = actualPage => {
        // If authentication was bypassed, we're not from back office and not from tutor access (readOnly === undefined) and we're not on ORDER page or LEGAL NOTICE page
        if (
            this.props.wasAuthenticationBypassed &&
            !this.props.isFromBackOffice &&
            this.props.isReadOnly === undefined &&
            !startsWith(actualPage, "ORDER") &&
            !startsWith(actualPage, "LEGAL_NOTICE")
        ) {
            // If order type is VIEW ORDER we redirect to concerned page
            if (this.props.orderType === Order.VIEW_ORDER) {
                this.props.history.push(PagesArchitecture[Page.VIEW_ORDER].route)
            } else {
                this.props.history.push(PagesArchitecture[Page.ORDER_INFORMATION].route)
            }
        }
    }

    initializeLoader = actualPage => {
        if (startsWith(actualPage, "LOGIN") && !this.props.isUserConnected) {
            this.props.hideFullScreenLoader()
        }

        if (startsWith(actualPage, "BYPASS_LOGIN_FROM_SAP")) {
            this.props.displayFullScreenLoader()
        }
    }

    checkReadOnly = actualPage => {
        const hiddenInReadOnlyMode = PagesArchitecture[actualPage].hiddenInReadOnlyMode
        if (hiddenInReadOnlyMode && this.props.isReadOnly) {
            this.props.history.push(PagesArchitecture[Page.UNAUTHORIZED].route)
        }
    }

    onDisconnectClick = () => {
        this.props.logoutUser()
        this.props.disconnectUser()
    }

    render() {
        const {
            actualPage,
            features,
            history,
            isContractWittholdingAllowed,
            isFromBackOffice,
            isMenuResponsive,
            isReadOnly,
            isUserConnected,
            onConnectClick,
            onContactClick,
            onCookiesClick,
            onHomeClick,
            onLegalClick,
            onProfileClick,
            onRgpdClick,
            onVisualisationChoiceClick,
            setMenuResponsive,
            userPermissions,
            username,
            visualisationMode,
            wasAuthenticationBypassed,
            displayNotificationFunction,
        } = this.props

        return (
            <>
                <CheckBrowserContainer userAgent={window.navigator.userAgent} />
                <MatomoTrackPageView />

                <NotificationsLayout>
                    <NotificationsContainer
                        displayNotifications={actualPage !== Page.LOGIN}
                        getNotifications={getNotifications}
                    />
                </NotificationsLayout>

                <Switch>
                    <Route actualPage={actualPage} component={RoutingLayout} path="" />
                </Switch>

                <Switch>
                    <Route
                        component={BypassLoginComponentEBUnique}
                        exact
                        path={PagesArchitecture[Page.BYPASS_LOGIN_FROM_SAP].route}
                    />
                </Switch>

                <CookiesNotificationContainer
                    getCookiesChoices={getCookiesChoices}
                    getModalStep={getModalStep}
                    isCookiesModalOpened={isCookiesModalOpened}
                    isCookiesUserAcceptanceExpired={isCookiesUserAcceptanceExpired}
                    isUserAcceptedCookies={isUserAcceptedCookies}
                    newButtons={true}
                />
            </>
        )
    }
}

MainLayout.propsTypes = {
    actualPage: PropTypes.string.isRequired,
    isMenuResponsive: PropTypes.bool.isRequired,
    onConnectClick: PropTypes.func.isRequired,
    onCookiesClick: PropTypes.func.isRequired,
    onContactClick: PropTypes.func.isRequired,
    onDisconnectClick: PropTypes.func.isRequired,
    onHomeClick: PropTypes.func.isRequired,
    onProfileClick: PropTypes.func.isRequired,
    onLegalClick: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    let actualPage = getActualPage(state)
    return {
        actualPage: actualPage,
        cesuRhContracts: getCesuRhContracts(state),
        cesuSocialContracts: getCesuSocialContracts(state),
        CESURHMessage: getCESURHMessage(state),
        features: getEnabledFeatures(state),
        isFromBackOffice: isFromBackOffice(state),
        isMenuResponsive: isMenuResponsive(state),
        isReadOnly: isReadOnly(state),
        isUserConnected: isUserConnected(state) && actualPage !== Page.BYPASS_LOGIN,
        isContractWittholdingAllowed: isContractWittholdingAllowed(state, getSelectedContract(state)),
        orderType: getOrderType(state),
        username: getUserFullname(state),
        userPermissions: getUserPermissions(state),
        visualisationMode: getVisualisationMode(state),
        wasAuthenticationBypassed: wasAuthenticationBypassed(state),
        displayNotificationFunction: featureName => getNotificationWithFeature(state, featureName),
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    // Display cookies modal
    onCookiesClick: () => dispatch(saveCookiesChoices({ isModalOpened: true, step: "CONFIGURATION" })),

    // Go to the contact page.
    onContactClick: () => window.open(DOMISERVE_CONTACT_URL),

    // Go to the connection page.
    onConnectClick: () => props.history.push(PagesArchitecture[Page.LOGIN].route),

    // Disconnect the current user.
    disconnectUser: () => dispatch(disconnectUser()),
    logoutUser: () => dispatch(logoutUser()),

    // On initialise le loader de redirection EB unique
    hideFullScreenLoader: () =>
        dispatch({
            type: DISPLAY_FULLSCREEN_LOADER,
            response: false,
        }),

    // On initialise le loader de redirection EB unique
    displayFullScreenLoader: () =>
        dispatch({
            type: DISPLAY_FULLSCREEN_LOADER,
            response: true,
        }),

    // Go to the home page.
    onHomeClick: () => {
        props.history.push(PagesArchitecture[Page.HOME].route)
    },
    // Go to the legal mentions page.
    onLegalClick: () => props.history.push(PagesArchitecture[Page.LEGAL_NOTICE].route),

    // Go to the rgpd page.
    onRgpdClick: () => props.history.push(PagesArchitecture[Page.RGPD].route),

    // Go to user profile page
    onProfileClick: () => props.history.push(PagesArchitecture[Page.USER_PROFILE_MAIN_INFORMATIONS].route),

    // Go to visualisation choice page
    onVisualisationChoiceClick: () => props.history.push(PagesArchitecture[Page.VISUALISATION_CHOICE].route),

    setMenuResponsive: isResponsive => {
        dispatch(setResponsive(isResponsive))
    },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainLayout))
