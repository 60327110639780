import React from "react"
import {useEffect} from "react"

import {isNil} from "lodash"
import {useDispatch} from "react-redux"
import {useHistory, useLocation} from "react-router"
import uuidv4 from "uuid"

import {Page} from "../../constants/page/pages"
import {PagesArchitecture} from "../../constants/page/pagesArchitecture"
import {getBeneficiaryEnabledFeatures} from "../../shared-components/actions/localStorage/features/featuresActions"
import {addNotification} from "../../shared-components/actions/notifications/notificationsActions"
import {
    bypassLoginEBUniqueCESU,
    connectedUserInfo,
} from "../../shared-components/actions/sessionStorage/user/userActions"
import {DISPLAY_FULLSCREEN_LOADER} from "../../shared-components/actions/sessionStorage/user/userActionTypes"
import {getBeneficiaryContracts} from "../../shared-components/actions/temporaries/beneficiary/beneficiaryActions"
import {changeActualPage} from "../../shared-components/actions/temporaries/page/pageActions"
import BypassLogin from "../../shared-components/components/BypassLogin/bypassLogin"
import {saveVisualisationChoice} from "../../actions/localStorage/visualisation/visualisationActions"

const BypassLoginComponentEBUnique = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const {search} = useLocation()

    const params = new URLSearchParams(search.substring(1))

    const userId = params.get("userId")
    const bypassToken = params.get("bypassToken")
    const iv = params.get("iv")
    const pages = params.get("pages")

    const missionMandatoryParams = !userId || !bypassToken || !iv || !pages

    const redirectAndDisplayError = error => {
        history.push(PagesArchitecture[Page.UNAUTHORIZED].route)
        addNotification({
            id: uuidv4(),
            type: "error",
            header: "Erreur",
            content: error,
            autoDismiss: false,
        })
    }

    const redirectToLogin = () => {
        history.push(PagesArchitecture[Page.LOGIN].route)
    }

    useEffect(() => {
        if (!isNil(userId) && !isNil(bypassToken) && !isNil(iv) && !isNil(pages)) {
            // Affichage du loader lors de la redirection
            dispatch({
                type: DISPLAY_FULLSCREEN_LOADER,
                response: true,
            })

            if (missionMandatoryParams) {
                redirectToLogin()
                return
            }

            dispatch(bypassLoginEBUniqueCESU(userId, iv, bypassToken, "ESPACE_BENEFICIAIRE"))
                .then(() => {
                    dispatch(connectedUserInfo()).then(() => {
                        dispatch(getBeneficiaryContracts()).then(contrats => {
                            // On récupère les contrats SAP liés au bénéficiaire et on garde le premier
                            const contratsSap = contrats.filter(contrat => contrat.contratSapFk != null)
                            const contrat = contratsSap[0]

                            if (!!contrat?.contractId) {
                                dispatch(saveVisualisationChoice(contrat?.contractType, contrat?.contractId))
                                dispatch(getBeneficiaryEnabledFeatures(contrat.contractId)).then(data => {
                                    history.push(PagesArchitecture[pages].route)
                                })
                            }

                            // On retire le loader à la fin de la redirection
                            dispatch({
                                type: DISPLAY_FULLSCREEN_LOADER,
                                response: false,
                            })
                        })
                    })
                })
                .catch(err => {
                    redirectAndDisplayError(err)
                })
        }
    }, [dispatch, userId, bypassToken, iv, pages])

    useEffect(() => {
        dispatch(changeActualPage(Page.BYPASS_LOGIN_FROM_SAP))
    }, [dispatch])

    return <BypassLogin />
}

export default BypassLoginComponentEBUnique
