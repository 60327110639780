import {Page} from "./pages"
import {Features} from "../../shared-components/constants/features"
import {VisualisationModes} from "../visualisationModes"

/**
 * Architecture of the pages of the application.
 * Structure :
 * - breadcrumbText : the text print in a breadcrumb
 * - route : the route to go to the page
 * - routeWithParamDesc : a second route, with param
 * - routeWithParam : a function take all params for the routeWithParamDesc and building a route
 * - parent : the parent page
 * - parentDisconnect : the parent page if no user is connected
 * - permissions: User permissions needed to access the page
 * - featureFlag : Tell if a page is subject to feature flagging by feature name
 */
export const PagesArchitecture = {
    [Page.BYPASS_LOGIN]: {
        route: "/bypass-login",
        userConnected: false,
    },
    [Page.BYPASS_LOGIN_FROM_SAP]: {
        route: "/bypass-login-from-sap",
        userConnected: false,
    },
    [Page.STYLE_GUIDE]: {
        route: "/style-guide",
        userConnected: false,
    },
    [Page.ACCOUNT_STATEMENT]: {
        parent: Page.HOME,
        breadcrumbText: "Mon solde",
        route: "/compte/solde",
        featureFlag: Features.LOGIN,
        userConnected: true,
        visualisationModes: [VisualisationModes.CESU_RH],
        hiddenInReadOnlyMode: false,
    },
    [Page.ACCOUNT_PAYMENT_CHOICE]: {
        parent: Page.HOME,
        breadcrumbText: "Payer mon intervenant",
        route: "/paiement/compte/choix",
        featureFlag: Features.LOGIN,
        userConnected: true,
        visualisationModes: [VisualisationModes.CESU_RH, VisualisationModes.CESU_SOCIAL],
        hiddenInReadOnlyMode: true,
    },
    [Page.ACCOUNT_PAYMENT_AMOUNT]: {
        parent: Page.HOME,
        breadcrumbText: "Payer mon intervenant",
        route: "/paiement/compte/montant",
        featureFlag: Features.LOGIN,
        userConnected: true,
        visualisationModes: [VisualisationModes.CESU_RH, VisualisationModes.CESU_SOCIAL],
        hiddenInReadOnlyMode: true,
    },
    [Page.ACCOUNT_PAYMENT_AMOUNT_OVERVIEW]: {
        parent: Page.HOME,
        breadcrumbText: "Payer mon intervenant",
        route: "/paiement/compte/recapitulatif",
        featureFlag: Features.LOGIN,
        userConnected: true,
        visualisationModes: [VisualisationModes.CESU_SOCIAL],
    },
    [Page.ACCOUNT_PAYMENT_HISTORY]: {
        breadcrumbText: "Historique des paiements",
        featureFlag: Features.LOGIN,
        parent: Page.HOME,
        route: "/historique/paiements-comptes",
        userConnected: true,
        visualisationModes: [VisualisationModes.CESU_RH],
    },
    [Page.ACCOUNT_PAYMENT_CONFIRMATION]: {
        parent: Page.HOME,
        breadcrumbText: "Payer mon intervenant",
        route: "/paiement/compte/confirmation",
        featureFlag: Features.LOGIN,
        userConnected: true,
        visualisationModes: [VisualisationModes.CESU_RH, VisualisationModes.CESU_SOCIAL],
    },
    [Page.ACCOUNT_PAYMENT_ORGANIZATION]: {
        parent: Page.HOME,
        breadcrumbText: "Organisme",
        route: "/paiement/compte/organisme",
        featureFlag: Features.LOGIN,
        userConnected: true,
        visualisationModes: [VisualisationModes.CESU_RH, VisualisationModes.CESU_SOCIAL],
    },
    [Page.ACCOUNT_PAYMENT_CESU_IDENTIFICATION]: {
        parent: Page.HOME,
        breadcrumbText: "Identification",
        route: "/paiement/compte/identification-cesu",
        featureFlag: Features.LOGIN,
        userConnected: true,
        visualisationModes: [VisualisationModes.CESU_RH, VisualisationModes.CESU_SOCIAL],
    },
    [Page.ACCOUNT_PAYMENT_PAJE_IDENTIFICATION]: {
        parent: Page.HOME,
        breadcrumbText: "Identification",
        route: "/paiement/compte/identification-paje",
        featureFlag: Features.LOGIN,
        userConnected: true,
        visualisationModes: [VisualisationModes.CESU_RH, VisualisationModes.CESU_SOCIAL],
    },
    [Page.ACCOUNT_PAYMENT_CESU_DECLARATION]: {
        parent: Page.HOME,
        breadcrumbText: "Déclaration",
        route: "/paiement/compte/declaration-cesu",
        featureFlag: Features.LOGIN,
        userConnected: true,
        visualisationModes: [VisualisationModes.CESU_RH, VisualisationModes.CESU_SOCIAL],
    },
    [Page.ACCOUNT_PAYMENT_PAJE_DECLARATION]: {
        parent: Page.HOME,
        breadcrumbText: "Déclaration",
        route: "/paiement/compte/declaration-paje",
        featureFlag: Features.LOGIN,
        userConnected: true,
        visualisationModes: [VisualisationModes.CESU_RH, VisualisationModes.CESU_SOCIAL],
    },
    [Page.CESU_CONVERSION_CHOICE]: {
        breadcrumbText: "Changement de format",
        featureFlag: Features.CONVERT_SUB_MENU,
        parent: Page.HOME,
        route: "/changer-format/choix",
        userConnected: true,
        visualisationModes: [VisualisationModes.CESU_RH],
        hiddenInReadOnlyMode: true,
    },
    [Page.CESU_CONVERSION_AMOUNT]: {
        breadcrumbText: "Changement de format",
        featureFlag: Features.CONVERT_SUB_MENU,
        parent: Page.HOME,
        route: "/changer-format/montant",
        userConnected: true,
        visualisationModes: [VisualisationModes.CESU_RH],
        hiddenInReadOnlyMode: true,
    },
    [Page.CESU_CONVERSION_DELIVERY]: {
        breadcrumbText: "Changement de format",
        featureFlag: Features.CONVERT_SUB_MENU,
        parent: Page.HOME,
        route: "/changer-format/livraison",
        userConnected: true,
        visualisationModes: [VisualisationModes.CESU_RH],
        hiddenInReadOnlyMode: true,
    },
    [Page.CESU_CONVERSION_CONFIRMATION]: {
        breadcrumbText: "Changement de format",
        featureFlag: Features.CONVERT_SUB_MENU,
        parent: Page.HOME,
        route: "/changer-format/confirmation",
        userConnected: true,
        visualisationModes: [VisualisationModes.CESU_RH],
        hiddenInReadOnlyMode: true,
    },
    [Page.CHECK_PAYMENT_CHOICE]: {
        breadcrumbText: "Payer mon intervenant",
        featureFlag: Features.DISPLAY_CHECKS,
        parent: Page.HOME,
        route: "/paiement/titre/choix",
        userConnected: true,
        visualisationModes: [VisualisationModes.CESU_RH],
    },
    [Page.CHECK_PAYMENT_AMOUNT]: {
        breadcrumbText: "Payer mon intervenant",
        featureFlag: Features.DISPLAY_CHECKS,
        parent: Page.HOME,
        route: "/paiement/titre/montant",
        userConnected: true,
        visualisationModes: [VisualisationModes.CESU_RH],
    },
    [Page.CHECK_PAYMENT_CONFIRMATION]: {
        breadcrumbText: "Payer mon intervenant",
        featureFlag: Features.DISPLAY_CHECKS,
        parent: Page.HOME,
        route: "/paiement/titre/confirmation",
        userConnected: true,
        visualisationModes: [VisualisationModes.CESU_RH],
    },
    [Page.CHECK_PAYMENT_HISTORY]: {
        breadcrumbText: "Historique des paiements",
        featureFlag: Features.DISPLAY_CHECKS,
        parent: Page.HOME,
        route: "/historique/paiements-titres",
        userConnected: true,
        visualisationModes: [VisualisationModes.CESU_RH],
    },
    [Page.CHECKBOOK_STATEMENT]: {
        breadcrumbText: "Mes titres",
        featureFlag: Features.DISPLAY_CHECKS,
        parent: Page.HOME,
        route: "/titres/solde",
        userConnected: true,
        visualisationModes: [VisualisationModes.CESU_RH],
    },
    [Page.DECLARATION_HISTORY]: {
        breadcrumbText: "Historique des déclarations",
        featureFlag: Features.LOGIN,
        route: "/historique/declarations",
        parent: Page.HOME,
        userConnected: true,
        visualisationModes: [VisualisationModes.CESU_RH, VisualisationModes.CESU_SOCIAL],
    },
    [Page.VISUALISATION_CHOICE]: {
        breadcrumbText: "Choix de visualisation",
        route: "/choix-visualisation",
        userConnected: true,
    },
    [Page.HOME]: {
        breadcrumbText: "Accueil",
        route: "/",
        userConnected: true,
    },
    [Page.HELP]: {
        breadcrumbText: "Aide",
        route: "/aide",
        userConnected: true,
    },
    [Page.LOGIN]: {
        breadcrumbText: "Connexion",
        route: "/login",
        routeWithParamDesc: "/:contractId/login",
        routeWithParam: contractId => "/" + contractId + "/login",
    },
    [Page.LEGAL_NOTICE]: {
        parentDisconnect: Page.LOGIN,
        parent: Page.HOME,
        breadcrumbText: "Mentions Légales",
        route: "/mentions-legales",
    },
    [Page.RGPD]: {
        parentDisconnect: Page.LOGIN,
        parent: Page.HOME,
        breadcrumbText: "Données personnelles",
        route: "/rgpd",
    },
    [Page.NOT_FOUND]: {
        breadcrumbText: "Page introuvable",
        route: "/notfound",
    },
    [Page.EDIT_ORDER_PROFILE]: {
        breadcrumbText: "Modifier une commande",
        route: "/commande/profil",
        userConnected: true,
        hiddenInReadOnlyMode: true,
    },
    [Page.MILLESIME_TRADE_CHOICE]: {
        breadcrumbText: "Échange de millésime",
        featureFlag: Features.TRADE_SUB_MENU,
        parent: Page.HOME,
        route: "/echange/choix",
        userConnected: true,
        visualisationModes: [VisualisationModes.CESU_RH],
        hiddenInReadOnlyMode: true,
    },
    [Page.MILLESIME_TRADE_DELIVERY]: {
        breadcrumbText: "Échange de millésime",
        featureFlag: Features.TRADE_SUB_MENU,
        parent: Page.HOME,
        route: "/echange/livraison",
        userConnected: true,
        visualisationModes: [VisualisationModes.CESU_RH],
        hiddenInReadOnlyMode: true,
    },
    [Page.MILLESIME_TRADE_PAYMENT]: {
        breadcrumbText: "Échange de millésime",
        featureFlag: Features.TRADE_SUB_MENU,
        parent: Page.HOME,
        route: "/echange/paiement",
        userConnected: true,
        visualisationModes: [VisualisationModes.CESU_RH],
        hiddenInReadOnlyMode: true,
    },
    [Page.MILLESIME_TRADE_CONFIRMATION]: {
        breadcrumbText: "Échange de millésime",
        featureFlag: Features.TRADE_SUB_MENU,
        parent: Page.HOME,
        route: "/echange/confirmation",
        userConnected: true,
        visualisationModes: [VisualisationModes.CESU_RH],
        hiddenInReadOnlyMode: true,
    },
    [Page.ORDER_INFORMATION]: {
        breadcrumbText: "Informations",
        featureFlag: Features.MAKE_ORDER_SUB_MENU,
        parent: Page.HOME,
        route: "/commande/informations",
        userConnected: true,
        visualisationModes: [VisualisationModes.CESU_RH],
        hiddenInReadOnlyMode: true,
    },
    [Page.ORDER_CONTENT]: {
        breadcrumbText: "Ma commande",
        featureFlag: Features.MAKE_ORDER_SUB_MENU,
        route: "/commande/contenu",
        userConnected: true,
        visualisationModes: [VisualisationModes.CESU_RH],
        hiddenInReadOnlyMode: true,
    },
    [Page.ORDER_CONFIRMATION]: {
        breadcrumbText: "Confirmation",
        featureFlag: Features.MAKE_ORDER_SUB_MENU,
        route: "/commande/confirmation",
        userConnected: true,
        visualisationModes: [VisualisationModes.CESU_RH],
        hiddenInReadOnlyMode: true,
    },
    [Page.ORDER_HISTORY]: {
        breadcrumbText: "Historique",
        featureFlag: Features.LOGIN,
        route: "/historique/commande",
        parent: Page.HOME,
        userConnected: true,
        visualisationModes: [VisualisationModes.CESU_RH],
    },
    [Page.ORDER_DELIVERY]: {
        breadcrumbText: "Livraison",
        featureFlag: Features.MAKE_ORDER_SUB_MENU,
        route: "/commande/livraison",
        userConnected: true,
        visualisationModes: [VisualisationModes.CESU_RH],
        hiddenInReadOnlyMode: true,
    },
    [Page.ORDER_PAYMENT]: {
        breadcrumbText: "Paiement",
        featureFlag: Features.MAKE_ORDER_SUB_MENU,
        route: "/commande/paiement",
        userConnected: true,
        visualisationModes: [VisualisationModes.CESU_RH],
        hiddenInReadOnlyMode: true,
    },
    [Page.ORDER_PROFILE]: {
        breadcrumbText: "Passer une précommande",
        featureFlag: Features.MAKE_ORDER_SUB_MENU,
        route: "/commande/profil",
        userConnected: true,
        visualisationModes: [VisualisationModes.CESU_RH],
        hiddenInReadOnlyMode: true,
    },
    [Page.ORDER_SESSION_LIST]: {
        breadcrumbText: "Précommande",
        featureFlag: Features.MAKE_ORDER_SUB_MENU,
        parent: Page.HOME,
        route: "/sessions",
        userConnected: true,
        visualisationModes: [VisualisationModes.CESU_RH],
        hiddenInReadOnlyMode: true,
    },
    [Page.RECOVER_LOGIN_PASSWORD]: {
        breadcrumbText: "Mot de passe oublié",
        parent: Page.LOGIN,
        route: "/mot-de-passe-oublie",
    },
    [Page.REGISTRATION]: {
        breadcrumbText: "Connexion / Inscription",
        route: "/inscription",
    },
    [Page.REGISTRATION_CONFIRMATION]: {
        breadcrumbText: "Confirmation",
        parent: Page.REGISTRATION,
        route: "/inscription/confirmation",
    },
    [Page.REGISTRATION_CONTROL]: {
        breadcrumbText: "Contrôle",
        parent: Page.REGISTRATION,
        route: "/inscription/controle",
    },
    [Page.REGISTRATION_PROFILE]: {
        breadcrumbText: "Profil",
        parent: Page.REGISTRATION,
        route: "/inscription/profil",
    },
    [Page.REGISTRATION_PASSWORD]: {
        breadcrumbText: "Mot de passe",
        parent: Page.REGISTRATION,
        route: "/inscription/mot-de-passe",
    },
    [Page.REGISTRATION_SESSION_CHOICE]: {
        breadcrumbText: "Choix de la session",
        parent: Page.REGISTRATION,
        route: "/inscription/session",
    },
    [Page.RESET_PASSWORD]: {
        breadcrumbText: "Réinitialiser son mot de passe",
        routeWithParamDesc: "/reinitialiser-mot-de-passe/:token",
        routeWithParam: token => "/reinitialiser-mot-de-passe/" + token,
    },
    [Page.TAX_DOCUMENT]: {
        breadcrumbText: "Attestations fiscales",
        featureFlag: Features.ACTIVE_TAX_DOCUMENT_SUB_MENU,
        parent: Page.HOME,
        route: "/attestation-fiscale",
        userConnected: true,
        visualisationModes: [VisualisationModes.CESU_RH, VisualisationModes.CESU_SOCIAL],
        hiddenInReadOnlyMode: true,
    },
    [Page.TECHNICAL_ERROR]: {
        route: "/erreur-technique",
    },
    [Page.TRANSACTIONS_HISTORY]: {
        parent: Page.HOME,
        breadcrumbText: "Historique des versements",
        route: "/historique/versements",
        userConnected: true,
        visualisationModes: [VisualisationModes.CESU_SOCIAL],
    },
    [Page.UNAUTHORIZED]: {
        breadcrumbText: "Accès interdit",
        route: "/unauthorized",
        parent: Page.HOME,
    },
    [Page.USER_PROFILE_AUTHENTICATION_INFORMATIONS]: {
        breadcrumbText: "Données personnelles",
        route: "/profil/infos-connexion",
        userConnected: true,
        parent: Page.HOME,
        visualisationModes: [VisualisationModes.CESU_RH, VisualisationModes.CESU_SOCIAL],
        hiddenInReadOnlyMode: true,
    },
    [Page.USER_PROFILE_DECLARATION_INFORMATIONS]: {
        breadcrumbText: "Informations de déclaration",
        route: "/profil/infos-declaration",
        userConnected: true,
        parent: Page.HOME,
        visualisationModes: [VisualisationModes.CESU_RH, VisualisationModes.CESU_SOCIAL],
        hiddenInReadOnlyMode: true,
    },
    [Page.USER_PROFILE_PRODUCT_CHOICE]: {
        breadcrumbText: "Mode de versement CESU",
        route: "/profil/choix-mode-versement",
        userConnected: true,
        parent: Page.HOME,
        visualisationModes: [VisualisationModes.CESU_RH, VisualisationModes.CESU_SOCIAL],
        hiddenInReadOnlyMode: true,
    },
    [Page.USER_PROFILE_MAIN_INFORMATIONS]: {
        breadcrumbText: "Données personnelles",
        route: "/profil/infos-principales",
        userConnected: true,
        parent: Page.HOME,
        visualisationModes: [VisualisationModes.CESU_RH, VisualisationModes.CESU_SOCIAL],
        hiddenInReadOnlyMode: true,
    },
    [Page.VIEW_ORDER]: {
        breadcrumbText: "Détail de la commande",
        route: "/commande/details",
        userConnected: true,
        visualisationModes: [VisualisationModes.CESU_RH],
    },
}
