import * as fromLocalStorage from "../../localStorage/localStorageReducer"
import {ClientContractTypes} from "../../../shared-components/constants/contractType"
import {isEmpty} from "lodash"
/**
 * Return contracts branch from localstorage
 */
export const getContracts = state => fromLocalStorage.getContracts(state.localStorage)

/**
 * Return clientId from contracts
 */
export const getContractClientId = (state, contractId) => {
    const contracts = getContracts(state)
    let clientId
    contracts.forEach(item => {
        if (item.contractId === contractId) clientId = item.clientId
    })
    return clientId
}

/**
 * Return CESU RH contracts
 */
export const getCesuRhContracts = state => {
    const contracts = getContracts(state)
    return !isEmpty(contracts)
        ? contracts.filter(contract => contract.contractType === ClientContractTypes.CESU_RH)
        : {}
}

/**
 * Return CESU Social contracts
 */
export const getCesuSocialContracts = state => {
    const contracts = getContracts(state)
    return !isEmpty(contracts)
        ? contracts.filter(contract => contract.contractType === ClientContractTypes.CESU_SOCIAL)
        : {}
}

/**
 * Return allowProductTypeChoice for a contract
 */
export const getAllowProductTypeChoice = (state, contractId) => {
    const contracts = getContracts(state)

    if (Array.isArray(contracts)) {
        const filteredContracts = contracts.filter(contract => contract.contractId === contractId)
        if (filteredContracts.length > 0) {
            return filteredContracts[0].allowProductTypeChoice
        }
    }

    return false
}

/**
 * Return if a contract has wittholding access
 */
export const isContractWittholdingAllowed = (state, contractId) => {
    const contracts = getContracts(state)

    if (Array.isArray(contracts)) {
        let filteredContracts = contracts.filter(contract => contract.allowWithholding)

        if (contractId) {
            filteredContracts = filteredContracts.filter(contract => contract.contractId === contractId)
        }

        if (filteredContracts.length > 0) {
            return true
        }
    }

    return false
}

// Retourne si le contrat est un contrat EB unique ou non
export const isContractEbUnique = (state, contractId) => {
    const contracts = getContracts(state)

    if (Array.isArray(contracts)) {
        const filteredContracts = contracts.filter(
            contract => contract.contractId === contractId && contract.contratSapFk !== null,
        )

        if (filteredContracts.length > 0) {
            return filteredContracts[0].contratSapFk != null
        }
    }
    return false
}
