import {get, patch, post} from "axios"
import {
    AUTH_ENDPOINTS,
    CONNECT_USER_ENDPOINT,
    PASSWORD_ENDPOINTS,
    SERVIDOM_ENDPOINTS,
    SIDOMI_ENDPOINTS,
} from "../../../constants/endpoints"
import {getErrorMessage} from "../../../services/api"
import {addTokenToHeader, removeTokenFromHeader} from "../../../services/configureAxios"
import {REQUEST, REQUEST_FAILURE, REQUEST_SUCCESS} from "../../../store/requests/requestsReducer"
import {CommonRequestKeys} from "../../constants/requests"
import {
    CONNECT_USER_FAILURE,
    CONNECT_USER_REQUEST,
    CONNECT_USER_SUCCESS,
    DISCONNECT_USER,
    RECOVER_LOGIN_PASSWORD_FAILURE,
    RECOVER_LOGIN_PASSWORD_REQUEST,
    RECOVER_LOGIN_PASSWORD_SUCCESS,
    SET_BYPASSED_AUTHENTICATION,
} from "./userActionTypes"

/**
 * Thunk to connect a user.
 * Dispatch on request and on success or failure.
 * @param login the user name
 * @param password the user password
 * @param typeEspace the application area where you want to connect
 */
export const connectUser = (login, password, typeEspace) => dispatch => {
    dispatch({
        type: REQUEST,
        requestKeyName: CommonRequestKeys.USER_AUTHORITY,
    })

    // Build request body.
    const body = {
        login,
        password,
        typeEspace,
    }

    return post(CONNECT_USER_ENDPOINT, body).then(
        response => {
            if (response.data.token) {
                addTokenToHeader(response.data.token)
                dispatch({
                    type: REQUEST_SUCCESS,
                    requestKeyName: CommonRequestKeys.USER_AUTHORITY,
                    response: response.data,
                })
            }

            return Promise.resolve(response.data)
        },
        error => {
            dispatch({
                type: REQUEST_FAILURE,
                requestKeyName: CommonRequestKeys.USER_AUTHORITY,
                message: getErrorMessage(error),
            })
            removeTokenFromHeader()

            return Promise.reject()
        },
    )
}

/**
 * Thunk to disconnect an user locally
 */
export const disconnectUser = () => dispatch => {
    dispatch({
        type: DISCONNECT_USER,
    })
    removeTokenFromHeader()
    return Promise.resolve()
}

export const logoutUser = () => dispatch => {
    dispatch({
        type: REQUEST,
        requestKeyName: CommonRequestKeys.USER_LOGOUT,
    })

    const headers = {
        "X-AUTH-REFRESH": false,
    }

    return post(AUTH_ENDPOINTS.logout, undefined, {headers})
        .then(response => {
            dispatch({
                type: REQUEST_SUCCESS,
                requestKeyName: CommonRequestKeys.USER_LOGOUT,
                response: response.data,
            })
            return Promise.resolve()
        })
        .catch(error => {
            dispatch({
                type: REQUEST_FAILURE,
                requestKeyName: CommonRequestKeys.USER_LOGOUT,
                message: getErrorMessage(error),
            })
            return Promise.reject()
        })
}

/**
 * Thunk to start the recover login/password process
 */
export const recoverLoginPassword = (typeEspace, login, siret, birthDate) => dispatch => {
    dispatch({
        type: RECOVER_LOGIN_PASSWORD_REQUEST,
    })

    const params = {
        typeEspace,
        login,
        siret,
        birthDate,
    }

    return get(AUTH_ENDPOINTS.recoverLoginPassword, {params}).then(
        response => {
            const data = {
                clientType: response.data.typeClient,
                mailSentTo: response.data.destinataires,
            }
            dispatch({
                type: RECOVER_LOGIN_PASSWORD_SUCCESS,
                response: data,
            })

            return Promise.resolve(data)
        },
        error => {
            const message = getErrorMessage(error)
            dispatch({
                type: RECOVER_LOGIN_PASSWORD_FAILURE,
                message,
            })

            return Promise.reject(message)
        },
    )
}

/**
 * Change password with a reset password token
 * Used when user has forgotten his password
 * @param {*} newPassword
 * @param {*} resetPasswordToken
 * @param {*} passwordRequestKeyName Nécessaire pour Prestadom et les autres espaces qui utilisent des clés différentes
 */
export const changePassword = (newPassword, resetPasswordToken, passwordRequestKeyName) => dispatch => {
    dispatch({
        type: REQUEST,
        requestKeyName: passwordRequestKeyName,
    })

    const body = {
        nouveauMotDePasse: newPassword,
        resetPasswordToken,
    }

    return post(PASSWORD_ENDPOINTS.changePassword, body).then(
        response => {
            dispatch({
                type: REQUEST_SUCCESS,
                requestKeyName: passwordRequestKeyName,
            })

            let promise
            if (response && response.data && response.data.token) {
                addTokenToHeader(response.data.token)
                dispatch({
                    type: REQUEST_SUCCESS,
                    requestKeyName: CommonRequestKeys.USER_AUTHORITY,
                    response: response.data,
                })

                promise = response.data
            }

            return Promise.resolve(promise)
        },
        error => {
            dispatch({
                type: REQUEST_FAILURE,
                requestKeyName: passwordRequestKeyName,
                message: getErrorMessage(error),
            })

            return Promise.reject(getErrorMessage(error))
        },
    )
}

/**
 * Change user password when he is connected
 * Thunk to give the possibility to a user to change his password into his authentication informations
 * @param oldPassword   oldPassword user
 * @param newPassword   newPassword user
 * @param newPasswordConfirmation   newPasswordConfirmation user
 *  */
export const changeActualPassword = (oldPassword, newPassword, newPasswordConfirmation) => dispatch => {
    dispatch({
        type: REQUEST,
        requestKeyName: CommonRequestKeys.CHANGE_ACTUAL_PASSWORD,
    })

    const body = {
        ancienMotDePasse: oldPassword,
        nouveauMotDePasse: newPassword,
        nouveauMotDePasseConfirmation: newPasswordConfirmation,
    }

    return patch(PASSWORD_ENDPOINTS.changeActualPassword, body).then(
        () => {
            dispatch({
                type: REQUEST_SUCCESS,
                requestKeyName: CommonRequestKeys.CHANGE_ACTUAL_PASSWORD,
            })

            return Promise.resolve()
        },
        error => {
            const message = getErrorMessage(error)
            dispatch({
                type: REQUEST_FAILURE,
                requestKeyName: CommonRequestKeys.CHANGE_ACTUAL_PASSWORD,
                message,
            })

            return Promise.reject(message)
        },
    )
}

/**
 * Thunk to mimiq user connection.
 * Dispatch on request and on success or failure.
 * @param sourceId the ciphered user id
 * @param userLogin the ciphered user login
 * @param areaType the application area where you want to connect
 * @param bypassToken the token used to bypass the login
 */
export const bypassLogin = (sourceId, userLogin, bypassToken, areaType, preOrderSessionId) => dispatch => {
    dispatch({
        type: CONNECT_USER_REQUEST,
    })

    // Build request body.
    const body = {
        identifiantSource: sourceId,
        identifiantCible: userLogin,
        jetonConnexion: bypassToken,
        typeEspace: areaType,
        idSessionPreCommande: preOrderSessionId ? preOrderSessionId : null,
    }

    return post(AUTH_ENDPOINTS.bypassLogin, body).then(
        response => {
            dispatch({
                type: CONNECT_USER_SUCCESS,
                response: response.data,
            })
            dispatch({
                type: SET_BYPASSED_AUTHENTICATION,
            })
            addTokenToHeader(response.data.token)

            return Promise.resolve()
        },
        error => {
            const message = getErrorMessage(error)
            dispatch({
                type: CONNECT_USER_FAILURE,
                message,
            })
            removeTokenFromHeader()

            return Promise.reject(message)
        },
    )
}

/**
 * Thunk to mimiq user connection.
 * Dispatch on request and on success or failure.
 * @param idCompteSap id de compte servidom
 */
export const getBypassToken = (idCompteSap, contractId) => dispatch => {
    dispatch({
        type: REQUEST,
        requestKeyName: CommonRequestKeys.GET_BYPASS_TOKEN_SERVIDOM,
    })

    const params = {
        userAccountId: idCompteSap,
        numeroContrat: contractId,
    }
    return get(SERVIDOM_ENDPOINTS.bypassServidom, {params}).then(
        response => {
            dispatch({
                type: REQUEST_SUCCESS,
                requestKeyName: CommonRequestKeys.GET_BYPASS_TOKEN_SERVIDOM,
            })

            return Promise.resolve(response?.data)
        },
        error => {
            const message = getErrorMessage(error)
            dispatch({
                type: REQUEST_FAILURE,
                requestKeyName: CommonRequestKeys.GET_BYPASS_TOKEN_SERVIDOM,
                message,
            })

            return Promise.reject(message)
        },
    )
}

/**
 * Thunk to mimiq user connection.
 * Dispatch on request and on success or failure.
 * @param idCompteCesu id de compte sidomi
 */
export const getBypassTokenSidomi = () => dispatch => {
    dispatch({
        type: REQUEST,
        requestKeyName: CommonRequestKeys.GET_BYPASS_TOKEN_SIDOMI,
    })

    return get(SIDOMI_ENDPOINTS.bypassSidomi).then(
        response => {
            dispatch({
                type: REQUEST_SUCCESS,
                requestKeyName: CommonRequestKeys.GET_BYPASS_TOKEN_SIDOMI,
            })

            return Promise.resolve(response?.data)
        },
        error => {
            const message = getErrorMessage(error)
            dispatch({
                type: REQUEST_FAILURE,
                requestKeyName: CommonRequestKeys.GET_BYPASS_TOKEN_SIDOMI,
                message,
            })

            return Promise.reject(message)
        },
    )
}
