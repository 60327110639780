import {get, patch, post} from "axios"
import {keys} from "lodash"
import {CesuAccountType} from "../../../constants/cesuAccountType"
import {BENEFICIARY_ENDPOINTS} from "../../../constants/endpoints"
import HttpMethod from "../../../constants/httpMethods"
import {RequestInfo, RequestKeys} from "../../../constants/requests"
import {getErrorMessage} from "../../../services/api"
import {REQUEST, REQUEST_FAILURE, REQUEST_SUCCESS} from "../../../store/requests/requestsReducer"
import {genericThunk} from "../../actionsUtils"

export const getOrder = (sessionId, preOrderProfile, preOrderContent, preOrderDelivery, preOrderPayment) => {
    const informationsControles = []
    keys(preOrderProfile.complementaryInformations)
        .filter(key => key.startsWith("controlField-"))
        .forEach(key => {
            const controlId = key.replace("controlField-", "")
            informationsControles.push({
                idControle: controlId,
                codeControle: preOrderProfile.complementaryInformations[controlId],
                valeur: preOrderProfile.complementaryInformations[key],
            })
        })

    let order = {
        idSession: sessionId,
        profilPreCommande: {
            emailContact: preOrderProfile.communicationInformations.contactMail,
            confirmationEmailContact: preOrderProfile.communicationInformations.contactMailConfirmation,
            civilite: preOrderProfile.communicationInformations.civility,
            dateNaissance: preOrderProfile.communicationInformations.birthDate,
            zoneAdresse0: preOrderProfile.communicationInformations.zoneAddress0,
            zoneAdresse1: preOrderProfile.communicationInformations.zoneAddress1,
            zoneAdresse2: preOrderProfile.communicationInformations.zoneAddress2,
            zoneAdresse3: preOrderProfile.communicationInformations.zoneAddress3,
            telephonePrincipal: preOrderProfile.communicationInformations.mainPhoneNumber,
            telephoneSecondaire: preOrderProfile.communicationInformations.secondaryPhoneNumber,
            codePostalEtVille: preOrderProfile.communicationInformations.postalCodeTown,
            idCategoriePersonnel: preOrderProfile.communicationInformations.staffCategoryId,
            informationsControles,
        },
    }

    if (preOrderContent) {
        order.montantsPreCommande = {
            montantTotal: preOrderContent.totalAmount,
            montantTitre: preOrderContent.checkbookAmount,
            valeurFaciale: preOrderContent.facialValue,
            montantCompte: preOrderContent.accountAmount,
            motifPreCommande: preOrderContent.preOrderCause,
            conditionsGenerales: preOrderContent.generalConditions,
        }
    }

    if (preOrderDelivery) {
        order.livraisonPreCommande = {
            typeLivraison: preOrderDelivery.deliveryType,
            pointLivraisonId: preOrderDelivery.deliveryPointId,
            zoneAdresse0: preOrderDelivery.zoneAddress0,
            zoneAdresse1: preOrderDelivery.zoneAddress1,
            zoneAdresse2: preOrderDelivery.zoneAddress2,
            zoneAdresse3: preOrderDelivery.zoneAddress3,
            codePostalEtVille: preOrderDelivery.postalCodeTown,
        }
    }

    if (preOrderPayment) {
        order.modePaiementPreCommande = {
            modePaiement: preOrderPayment.paymentMode,
        }
    }

    return order
}

/**
 * Get session complementary informations for beneficiary
 */
export const getSessionComplementaryInfoForBeneficiary = sessionId => dispatch => {
    dispatch({
        type: REQUEST,
        requestKeyName: RequestInfo[RequestKeys.GET_SESSION_COMPLEMENTARY_INFO_FOR_BENEFICIARY].name,
    })

    return get(BENEFICIARY_ENDPOINTS.getSessionComplementaryInfoForBeneficiary(sessionId)).then(
        response => {
            const payload = {}

            payload.complementaryInformations = {}
            response.data.informationsControles.forEach(item => {
                payload.complementaryInformations[item.idControle] = {
                    controlId: item.idControle,
                    controlCode: item.codeControle,
                    additionnal: item.additionnal,
                    label: item.libelle,
                    value: item.valeur,
                }
            })

            payload.staffCategories = {}
            response.data.categoriesPersonnel.forEach(item => {
                payload.staffCategories[item.categoriePersonnelId] = {
                    staffCategoryId: item.categoriePersonnelId,
                    label: item.libelle,
                    selected: item.selectionne,
                }
            })

            dispatch({
                type: REQUEST_SUCCESS,
                requestKeyName: RequestInfo[RequestKeys.GET_SESSION_COMPLEMENTARY_INFO_FOR_BENEFICIARY].name,
                payload,
            })

            return Promise.resolve(payload)
        },
        error => {
            dispatch({
                type: REQUEST_FAILURE,
                requestKeyName: RequestInfo[RequestKeys.GET_SESSION_COMPLEMENTARY_INFO_FOR_BENEFICIARY].name,
                message: getErrorMessage(error),
            })

            return Promise.reject()
        },
    )
}

/**
 * Check control informations to know if user has sent correct informations
 */
export const checkControlInformations = (controlInformations, sessionId) => dispatch => {
    dispatch({
        type: REQUEST,
        requestKeyName: RequestInfo[RequestKeys.CHECK_SESSION_CONTROL_INFORMATIONS_FOR_BENEFICIARY].name,
    })

    return post(BENEFICIARY_ENDPOINTS.checkControlInformations(sessionId), controlInformations).then(
        response => {
            dispatch({
                type: REQUEST_SUCCESS,
                requestKeyName: RequestInfo[RequestKeys.CHECK_SESSION_CONTROL_INFORMATIONS_FOR_BENEFICIARY].name,
                payload: response.data,
            })

            return Promise.resolve(response.data)
        },
        error => {
            dispatch({
                type: REQUEST_FAILURE,
                requestKeyName: RequestInfo[RequestKeys.CHECK_SESSION_CONTROL_INFORMATIONS_FOR_BENEFICIARY].name,
                message: getErrorMessage(error),
            })

            return Promise.reject()
        },
    )
}

/**
 * Get beneficiary contracts
 */
export const getBeneficiaryContracts = () => dispatch => {
    dispatch({
        type: REQUEST,
        requestKeyName: RequestInfo[RequestKeys.GET_BENEFICIARY_CONTRACTS].name,
    })

    return get(BENEFICIARY_ENDPOINTS.getContracts).then(
        response => {
            const contracts = []
            response.data.forEach(item => {
                contracts.push({
                    contractId: item.idContrat,
                    contractType: item.typeContrat,
                    clientId: item.idClient,
                    clientType: item.typeClient,
                    socialReason: item.raisonSociale,
                    paramEspaceCompteId: item.paramEspaceCompteId,
                    contratSapFk: item.contratSapFk,
                    supportedType: item.typePriseEnCharge,
                    contractActivated: item.contratActive,
                    cesuAccountType: item.typeCompte,
                    allowProductTypeChoice: item.autoriserChoixTypeProduit,
                    allowWithholding: item.autoriserPrelevementALaSource,
                    libelleProduitContrat: item.libelleProduitContrat,
                    autoriserPaiementTitre: item.autoriserPaiementTitre
                })
            })

            dispatch({
                type: REQUEST_SUCCESS,
                requestKeyName: RequestInfo[RequestKeys.GET_BENEFICIARY_CONTRACTS].name,
                payload: contracts,
            })

            return Promise.resolve(contracts)
        },
        error => {
            dispatch({
                type: REQUEST_FAILURE,
                requestKeyName: RequestInfo[RequestKeys.GET_BENEFICIARY_CONTRACTS].name,
                message: getErrorMessage(error),
            })

            return Promise.reject()
        },
    )
}

/**
 * Get beneficiary order ceiling
 * @param sessionId the session id
 * @param controlInformations list of control information
 */
export const getOrderCeiling = (sessionId, preOrderProfile) => dispatch => {
    dispatch({
        type: REQUEST,
        requestKeyName: RequestInfo[RequestKeys.GET_BENEFICIARY_ORDER_CEILING].name,
    })

    const informationsControles = []
    keys(preOrderProfile.complementaryInformations)
        .filter(key => key.startsWith("controlField-"))
        .forEach(key => {
            const controlId = key.replace("controlField-", "")
            informationsControles.push({
                idControle: controlId,
                valeur: preOrderProfile.complementaryInformations[key],
            })
        })

    const body = {
        idCategoriePersonnel: preOrderProfile.communicationInformations.staffCategoryId,
        informationsControles,
    }

    return post(BENEFICIARY_ENDPOINTS.getOrderCeiling(sessionId), body).then(
        response => {
            const payload = {
                employerContribution: response.data.tauxAbondement,
                maxOrder: response.data.maxPrecommande,
                maxParticipation: response.data.maxParticipation,
                initialMaxOrder: response.data.maxPrecommandeInitial,
                initialMaxParticipation: response.data.maxParticipationInitial,
                year: response.data.annee,
            }
            dispatch({
                type: REQUEST_SUCCESS,
                requestKeyName: RequestInfo[RequestKeys.GET_BENEFICIARY_ORDER_CEILING].name,
                payload,
            })

            return Promise.resolve(payload)
        },
        error => {
            dispatch({
                type: REQUEST_FAILURE,
                requestKeyName: RequestInfo[RequestKeys.GET_BENEFICIARY_ORDER_CEILING].name,
                message: getErrorMessage(error),
            })

            return Promise.reject()
        },
    )
}

/**
 * Get beneficiary and allocated parts
 * @param sessionId the session id
 * @param preOrderProfile profil information
 * @param preOrderContent order content information
 * @param source axios cancelToken
 * @param ignoredFailureMessage if the error message is this one, do not dispatch REQUEST_FAILURE
 */
export const getBeneficiaryParts =
    (sessionId, preOrderProfile, preOrderContent, source, ignoredFailureMessage) => dispatch => {
        dispatch({
            type: REQUEST,
            requestKeyName: RequestInfo[RequestKeys.GET_BENEFICIARY_ORDER_PARTS].name,
        })

        const body = getOrder(sessionId, preOrderProfile, preOrderContent)

        // If source is present, add the cancel token
        const options = source ? {cancelToken: source.token} : {}

        return post(BENEFICIARY_ENDPOINTS.getBeneficiaryPart(sessionId), body, options).then(
            response => {
                const payload = {
                    beneficiaryPart: response.data.partBeneficiaire,
                    customerPart: response.data.partFinancee,
                }

                dispatch({
                    type: REQUEST_SUCCESS,
                    requestKeyName: RequestInfo[RequestKeys.GET_BENEFICIARY_ORDER_PARTS].name,
                    payload,
                })

                return Promise.resolve(payload)
            },
            error => {
                // This is an attempt to avoid dispatching REQUEST_FAILURE when we cancel a request
                const errorMessage = getErrorMessage(error)
                if (errorMessage !== ignoredFailureMessage) {
                    dispatch({
                        type: REQUEST_FAILURE,
                        requestKeyName: RequestInfo[RequestKeys.GET_BENEFICIARY_ORDER_PARTS].name,
                        message: errorMessage,
                    })
                }

                return Promise.reject(errorMessage)
            },
        )
    }

/**
 * Create or update the beneficiary preOrder for the session
 * @param {*} sessionId
 * @param {*} preOrderProfile
 * @param {*} preOrderContent
 * @param {*} preOrderDelivery
 * @param {*} preOrderPayment
 */
export const createOrUpdatePreOrder =
    (sessionId, preOrderProfile, preOrderContent, preOrderDelivery, preOrderPayment) => dispatch => {
        dispatch({
            type: REQUEST,
            requestKeyName: RequestInfo[RequestKeys.CREATE_OR_UPDATE_PREORDER].name,
        })

        const body = getOrder(sessionId, preOrderProfile, preOrderContent, preOrderDelivery, preOrderPayment)

        return post(BENEFICIARY_ENDPOINTS.createOrUpdatePreOrder(sessionId), body).then(
            () => {
                dispatch({
                    type: REQUEST_SUCCESS,
                    requestKeyName: RequestInfo[RequestKeys.CREATE_OR_UPDATE_PREORDER].name,
                })

                return Promise.resolve()
            },
            error => {
                const message = getErrorMessage(error)
                dispatch({
                    type: REQUEST_FAILURE,
                    requestKeyName: RequestInfo[RequestKeys.CREATE_OR_UPDATE_PREORDER].name,
                    message,
                })

                return Promise.reject(message)
            },
        )
    }

/**
 * Update beneficiary profile main informations
 */
export const updateBeneficiaryProfileMainInformations = (beneficiaryProfile, contractId, contractType) => dispatch => {
    dispatch({
        type: REQUEST,
        requestKeyName: RequestInfo[RequestKeys.UPDATE_BENEFICIARY_PROFILE_MAIN_INFORMATIONS].name,
    })

    const body = {
        emailContact: beneficiaryProfile.contactMail,
        confirmationEmailContact: beneficiaryProfile.contactMailConfirmation,
        zoneAdresse0: beneficiaryProfile.zoneAddress0,
        zoneAdresse1: beneficiaryProfile.zoneAddress1,
        zoneAdresse2: beneficiaryProfile.zoneAddress2,
        zoneAdresse3: beneficiaryProfile.zoneAddress3,
        telephonePrincipal: beneficiaryProfile.mainPhoneNumber,
        telephoneSecondaire: beneficiaryProfile.secondaryPhoneNumber,
        codePostalEtVille: beneficiaryProfile.postalCodeTown,
        offresMail: beneficiaryProfile.mailOffers,
    }

    const params = {
        numeroContrat: contractId,
        typeContrat: contractType,
    }

    return patch(BENEFICIARY_ENDPOINTS.updateMainInformationsProfile, body, {params}).then(
        () => {
            dispatch({
                type: REQUEST_SUCCESS,
                requestKeyName: RequestInfo[RequestKeys.UPDATE_BENEFICIARY_PROFILE_MAIN_INFORMATIONS].name,
            })

            return Promise.resolve()
        },
        error => {
            const message = getErrorMessage(error)
            dispatch({
                type: REQUEST_FAILURE,
                requestKeyName: RequestInfo[RequestKeys.UPDATE_BENEFICIARY_PROFILE_MAIN_INFORMATIONS].name,
                message,
            })

            return Promise.reject(message)
        },
    )
}

/**
 * Get information to know if the beneficiary can convert something
 */
export const hasAccountOrChecksConvertibles = (contractId, visualisationMode) => dispatch => {
    dispatch({
        type: REQUEST,
        requestKeyName: RequestInfo[RequestKeys.HAS_ACCOUNTS_OR_CHECKS_CONVERTIBLES].name,
    })

    const params = {
        numeroContrat: contractId,
        typeContrat: visualisationMode,
    }

    return get(BENEFICIARY_ENDPOINTS.hasAccountsOrCheckbooksConvertibles, {params}).then(
        response => {
            const payload = {
                hasAccountsConvertibles: response.data.comptesTransformables,
                hasChecksConvertibles: response.data.titresTransformables,
            }

            dispatch({
                type: REQUEST_SUCCESS,
                requestKeyName: RequestInfo[RequestKeys.HAS_ACCOUNTS_OR_CHECKS_CONVERTIBLES].name,
                payload,
            })

            return Promise.resolve(payload)
        },
        error => {
            dispatch({
                type: REQUEST_FAILURE,
                requestKeyName: RequestInfo[RequestKeys.HAS_ACCOUNTS_OR_CHECKS_CONVERTIBLES].name,
                message: getErrorMessage(error),
            })

            return Promise.reject()
        },
    )
}

/**
 * Check for a checkNumber if secretCode is valid
 * @param checkNumber the title number
 * @param secretCode the title secretCode
 */
export const checkSecretCode = (checkNumber, secretCode) => dispatch => {
    dispatch({
        type: REQUEST,
        requestKeyName: RequestInfo[RequestKeys.CHECK_SECRET_CODE_TITLE].name,
    })

    const body = {
        numeroCheque: checkNumber,
        caseAGratter: secretCode,
    }

    return post(BENEFICIARY_ENDPOINTS.checkSecretCode, body).then(
        response => {
            const payload = {
                id: response.data.numeroCheque,
                checkNumber: response.data.numeroCheque,
                secretCode: response.data.caseAGratter,
                secretCodeValid: response.data.caseAGratterValide,
            }
            dispatch({
                type: REQUEST_SUCCESS,
                requestKeyName: RequestInfo[RequestKeys.CHECK_SECRET_CODE_TITLE].name,
                payload,
            })
            return Promise.resolve(payload)
        },
        error => {
            dispatch({
                type: REQUEST_FAILURE,
                requestKeyName: RequestInfo[RequestKeys.CHECK_SECRET_CODE_TITLE].name,
                message: getErrorMessage(error),
            })
            return Promise.reject()
        },
    )
}

/**
 * Convert account to checks
 */
export const convertAccountToChecks = (accountProduct, amount, facialValue, deliveryAddress) => dispatch => {
    dispatch({
        type: REQUEST,
        requestKeyName: RequestInfo[RequestKeys.CONVERT_ACCOUNT_TO_CHECKBOOK].name,
    })

    const body = {
        numeroCompteCesu: accountProduct,
        montantTotal: amount,
        valeurFaciale: facialValue,
    }

    if (deliveryAddress) {
        body.adresseTierce = {
            zoneAdresse0: deliveryAddress.zoneAddress0,
            zoneAdresse1: deliveryAddress.zoneAddress1,
            zoneAdresse2: deliveryAddress.zoneAddress2,
            zoneAdresse3: deliveryAddress.zoneAddress3,
            codePostalEtVille: deliveryAddress.postalCodeTown,
        }
    }

    return post(BENEFICIARY_ENDPOINTS.convertAccountToChecks, body).then(
        () => {
            dispatch({
                type: REQUEST_SUCCESS,
                requestKeyName: RequestInfo[RequestKeys.CONVERT_ACCOUNT_TO_CHECKBOOK].name,
            })
            return Promise.resolve()
        },
        error => {
            const message = getErrorMessage(error)
            dispatch({
                type: REQUEST_FAILURE,
                requestKeyName: RequestInfo[RequestKeys.CONVERT_ACCOUNT_TO_CHECKBOOK].name,
                message,
            })
            return Promise.reject(message)
        },
    )
}

/**
 * Convert checks to account
 */
export const convertChecksToAccount = (productId, millesime, checksToConvert) => dispatch => {
    dispatch({
        type: REQUEST,
        requestKeyName: RequestInfo[RequestKeys.CONVERT_CHECKBOOK_TO_ACCOUNT].name,
    })

    const body = {
        idProduit: productId,
        millesime,
        titresATransformer: checksToConvert.map(c => ({
            numeroTitre: c.checkNumber,
            caseAGratter: c.secretCode,
        })),
    }

    return post(BENEFICIARY_ENDPOINTS.convertChecksToAccount, body).then(
        () => {
            dispatch({
                type: REQUEST_SUCCESS,
                requestKeyName: RequestInfo[RequestKeys.CONVERT_CHECKBOOK_TO_ACCOUNT].name,
            })
            return Promise.resolve()
        },
        error => {
            const message = getErrorMessage(error)
            dispatch({
                type: REQUEST_FAILURE,
                requestKeyName: RequestInfo[RequestKeys.CONVERT_CHECKBOOK_TO_ACCOUNT].name,
                message,
            })
            return Promise.reject(message)
        },
    )
}

/**
 * Calculate reloading fees for intervenant payment
 */
export const calculateReloadingFees = (values, contractId, visualisationMode) => dispatch => {
    dispatch({
        type: REQUEST,
        requestKeyName: RequestInfo[RequestKeys.CALCULATE_RELOADING_FEES].name,
    })

    const body = {
        montant: values.amount,
        numeroComptesCESU: values.cesuAccountNumbers,
        codeNAN: values.nanCodeIntervenant,
        numeroContrat: contractId,
        typeContrat: visualisationMode,
    }

    return post(BENEFICIARY_ENDPOINTS.calculateReloadingFees, body).then(
        response => {
            dispatch({
                type: REQUEST_SUCCESS,
                requestKeyName: RequestInfo[RequestKeys.CALCULATE_RELOADING_FEES].name,
                payload: response.data.valeur,
            })
            return Promise.resolve()
        },
        error => {
            const message = getErrorMessage(error)
            dispatch({
                type: REQUEST_FAILURE,
                requestKeyName: RequestInfo[RequestKeys.CALCULATE_RELOADING_FEES].name,
                message,
            })
            return Promise.reject(message)
        },
    )
}

/**
 * Calculate payment complement fees for intervenant payment
 */
export const calculPaymentComplementFees = (values, contractId, visualisationMode) => dispatch => {
    const body = {
        codeNAN: values.nanCodeIntervenant,
        montantComplement: values.amount,
        numeroContrat: contractId,
        typeContrat: visualisationMode,
    }

    const url = BENEFICIARY_ENDPOINTS.calculPaymentComplementFees

    const payloadHandler = response => response.data.valeur

    return genericThunk(
        body,
        HttpMethod.POST,
        dispatch,
        url,
        undefined,
        RequestInfo[RequestKeys.CALCUL_PAYMENT_COMPLEMENT_FEES].name,
        payloadHandler,
    )
}

/**
 * Make an intervenant account payment request
 */
export const makeIntervenantAccountPaymentRequest =
    (values, contractId, visualisationMode, cesuAccountType) => dispatch => {
        dispatch({
            type: REQUEST,
            requestKeyName: RequestInfo[RequestKeys.MAKE_INTERVENANT_ACCOUNT_PAYMENT_REQUEST].name,
        })

        // If there is a declaration id for CesuAccountType.MINUTES or HUNDREDTH
        // We use amountPerMonth method
        const body = {
            montant: cesuAccountType === CesuAccountType.AMOUNT ? values.amount : undefined,
            numeroComptesCESU: values.cesuAccountNumbers,
            commentaire: values.comment,
            codeNAN: values.nanCodeIntervenant,
            numeroContrat: contractId,
            typeContrat: visualisationMode,
            montantParMois:
                cesuAccountType === CesuAccountType.AMOUNT_PER_MONTH || !!values.declarationId
                    ? values.amountPerMonth
                    : undefined,
            dureeParMois:
                !values.declarationId &&
                (cesuAccountType === CesuAccountType.MINUTE_PER_MONTH ||
                    cesuAccountType === CesuAccountType.HUNDREDTH_OF_HOUR_PER_MONTH)
                    ? values.minutesPerMonth
                    : undefined,
            toutUtiliser:
                !values.declarationId &&
                (cesuAccountType === CesuAccountType.MINUTE_PER_MONTH ||
                    cesuAccountType === CesuAccountType.HUNDREDTH_OF_HOUR_PER_MONTH)
                    ? values.useEverything
                    : undefined,
            complementPaiement: values.paymentComplement,
            idTeledeclaration: values.declarationId,
            idTeledeclarationPaje: values.pajeDeclarationId,
        }

        return post(BENEFICIARY_ENDPOINTS.makeBeneficiaryAccountPaymentRequest, body).then(
            response => {
                dispatch({
                    type: REQUEST_SUCCESS,
                    requestKeyName: RequestInfo[RequestKeys.MAKE_INTERVENANT_ACCOUNT_PAYMENT_REQUEST].name,
                })

                return Promise.resolve(response.data.valeur)
            },
            error => {
                const message = getErrorMessage(error)
                dispatch({
                    type: REQUEST_FAILURE,
                    requestKeyName: RequestInfo[RequestKeys.MAKE_INTERVENANT_ACCOUNT_PAYMENT_REQUEST].name,
                    message,
                })
                return Promise.reject(message)
            },
        )
    }

/**
 * Get payment request number by transaction reference
 */
export const getPaymentRequestNumberByTransactionReference = transactionReference => dispatch => {
    dispatch({
        type: REQUEST,
        requestKeyName: RequestInfo[RequestKeys.GET_PAYMENT_REQUEST_NUMBER_BY_TRANSACTION_REFERENCE].name,
    })

    const params = {
        referenceTransaction: transactionReference,
    }

    return get(BENEFICIARY_ENDPOINTS.makeBeneficiaryAccountPaymentRequest, {params}).then(
        response => {
            dispatch({
                type: REQUEST_SUCCESS,
                requestKeyName: RequestInfo[RequestKeys.GET_PAYMENT_REQUEST_NUMBER_BY_TRANSACTION_REFERENCE].name,
            })
            return Promise.resolve(response.data)
        },
        error => {
            const message = getErrorMessage(error)
            dispatch({
                type: REQUEST_FAILURE,
                requestKeyName: RequestInfo[RequestKeys.GET_PAYMENT_REQUEST_NUMBER_BY_TRANSACTION_REFERENCE].name,
                message,
            })
            return Promise.reject(message)
        },
    )
}

/**
 * Make an intervenant check payment request
 */
export const makeIntervenantCheckPaymentRequest = (values, contractId, visualisationMode) => dispatch => {
    dispatch({
        type: REQUEST,
        requestKeyName: RequestInfo[RequestKeys.MAKE_INTERVENANT_CHECK_PAYMENT_REQUEST].name,
    })

    const body = {
        codeNAN: values.nanCode,
        commentaire: values.comment,
        idProduit: values.productId,
        millesime: values.millesime,
        montant: values.amount,
        numeroContrat: contractId,
        titres: values.checks.map(c => ({
            numeroTitre: c.checkNumber,
            caseAGratter: c.secretCode,
        })),
        typeContrat: visualisationMode,
    }

    return post(BENEFICIARY_ENDPOINTS.makeBeneficiaryCheckPaymentRequest, body).then(
        response => {
            const data = {
                refundNumber: response.data.numeroRemise,
                refundDate: response.data.dateRemise,
            }

            dispatch({
                type: REQUEST_SUCCESS,
                requestKeyName: RequestInfo[RequestKeys.MAKE_INTERVENANT_CHECK_PAYMENT_REQUEST].name,
            })

            return Promise.resolve(data)
        },
        error => {
            const message = getErrorMessage(error)
            dispatch({
                type: REQUEST_FAILURE,
                requestKeyName: RequestInfo[RequestKeys.MAKE_INTERVENANT_CHECK_PAYMENT_REQUEST].name,
                message,
            })
            return Promise.reject(message)
        },
    )
}

/**
 * Retrieve the product type choice
 */
export const retrieveProductTypeChoice = (contractId, contractType) => dispatch => {
    const url = BENEFICIARY_ENDPOINTS.retrieveProductTypeChoice

    const params = {
        numeroContrat: contractId,
        typeContrat: contractType,
    }

    const payloadHandler = response => ({
        productTypeChoice: response.data.valeur,
    })

    return genericThunk(
        undefined,
        HttpMethod.GET,
        dispatch,
        url,
        params,
        RequestInfo[RequestKeys.RETRIEVE_PRODUCT_TYPE_CHOICE].name,
        payloadHandler,
    )
}

/**
 * Update the product type choice
 */
export const updateProductTypeChoice = (contractId, contractType, productTypeChoice) => dispatch => {
    const url = BENEFICIARY_ENDPOINTS.updateProductTypeChoice

    const params = {
        numeroContrat: contractId,
        typeContrat: contractType,
    }

    const body = {
        valeur: productTypeChoice,
    }

    return genericThunk(
        body,
        HttpMethod.PATCH,
        dispatch,
        url,
        params,
        RequestInfo[RequestKeys.UPDATE_PRODUCT_TYPE_CHOICE].name,
        undefined,
    )
}
