import colors from "../styles/_default.colors.module.scss"

// Regex correspondant à une valeur de couleur hexa sur 3 caractères
const REGEX_COLOR_HEXA_3 = /^#([a-f\d]{3})$/i

// Regex permettant de récupérer les 3 composantes d'une couleur hexa sur 3 caractères
const REGEX_COLOR_HEXA_3_DETAIL = /^#([a-f\d])([a-f\d])([a-f\d])$/i

// Regex permettant de récupérer les 3 composantes d'une couleur hexa sur 6 caractères
const REGEX_COLOR_HEXA_6_DETAIL = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i

// Permet de convertir une couleur hexadécimale (#RRGGBB ou RRGGBB) en composantes RGB
const hexToRgb = colorHexa => {
    // Si le code est en forme abrégée (#abc), on l'étend à 6 caractères (#aabbcc)
    if (REGEX_COLOR_HEXA_3.test(colorHexa)) {
        colorHexa = colorHexa.replace(REGEX_COLOR_HEXA_3_DETAIL, (_, r, g, b) => `#${r}${r}${g}${g}${b}${b}`)
    }

    // On vérifie si la valeur hexa est valide
    const result = REGEX_COLOR_HEXA_6_DETAIL.exec(colorHexa)

    // Si c'est valide, on convertit chaque composante hexadécimale en entier
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          }
        : null
}

// Permet de calculer la luminosité relative au moyen de la logique mathématique
// Explications : https://fr.planetcalc.com/7779/
const getRelativeLuminance = ({r, g, b}) => {
    const [R, G, B] = [r, g, b].map(v => {
        const sRGB = v / 255
        return sRGB <= 0.03928 ? sRGB / 12.92 : Math.pow((sRGB + 0.055) / 1.055, 2.4)
    })

    return 0.2126 * R + 0.7152 * G + 0.0722 * B
}

// Permet de calculer le contraste entre deux luminosités
const getContrastRatio = (l1, l2) => {
    return (Math.max(l1, l2) + 0.05) / (Math.min(l1, l2) + 0.05)
}

/**
 * Permet de vérifier si 2 couleurs sont assez contrastées et sont compatibles WCAG AA
 * @param {String} color1
 * @param {String} color2
 * @param {Boolean} isLargeText permet de réduire le seuil de contraste pour les gros textes
 * @returns un boolean indiquant si les couleurs sont compatibles
 */
export const validateColorsContrastAA = (color1, color2, isLargeText = false) => {
    const rgb1 = hexToRgb(color1)
    const rgb2 = hexToRgb(color2)

    if (!rgb1 || !rgb2) {
        return false
    }

    const luminance1 = getRelativeLuminance(rgb1)
    const luminance2 = getRelativeLuminance(rgb2)

    const contrastRatio = getContrastRatio(luminance1, luminance2)

    // Le niveau AA de WCAG 2.0 exige un rapport de contraste d'au moins 4,5:1 pour le texte normal et 3:1 pour le texte de grande taille
    const contrastMin = isLargeText ? 3 : 4.5

    return contrastRatio >= contrastMin
}

/**
 * Permet de selectionner la première couleur de la liste compatible avec la couleur de fond
 * @param {String} backgroundColor  la couleur de fond
 * @param {Array} textColors les couleurs de texte possibles
 * @param {Boolean} isLargeText permet de réduire le seuil de contraste pour les gros textes
 * @returns la couleur compatible
 */
export const getContrastedColor = (backgroundColor, textColors, isLargeText = false) => {
    // On ajoute deux couleurs par défaut à la liste textColors
    const extendedTextColors = [...textColors, colors.black, colors.white]

    // Retourne la première couleur valide de la liste textColors
    const validColor = extendedTextColors.find(textColor =>
        validateColorsContrastAA(backgroundColor, textColor, isLargeText),
    )

    return validColor || colors.black
}
