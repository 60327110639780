import {getNotDeprecatedCivility} from "../../../../utils/civility"
import {
    DISCONNECT_USER,
    GET_USER_INFO_SUCCESS,
    GET_USER_INFO_FAILURE,
} from "../../../../actions/sessionStorage/user/userActionTypes"

const details = (state = {}, action = {}) => {
    switch (action.type) {
        case GET_USER_INFO_SUCCESS:
            if (action.response.intervenant && action.response.intervenant.id) {
                return {
                    firstName: action.response.prenom,
                    lastName: action.response.nom,
                    socialReason: action.response.raisonSociale,
                    login: action.response.identifiant,
                    intervenant: {
                        id: action.response.intervenant.id,
                        type: action.response.intervenant.type,
                        contactStatus: action.response.intervenant.statutContact,
                    },
                }
            } else if (action.response.beneficiairePourEchange) {
                return {
                    firstName: action.response.prenom,
                    lastName: action.response.nom,
                    login: action.response.identifiant,
                    mail: action.response.email,
                    beneficiaryForTrade: {
                        phoneNumber: action.response.beneficiairePourEchange.telephone,
                        birthDate: action.response.beneficiairePourEchange.dateNaissance,
                        fromBackOffice: action.response.beneficiairePourEchange.viaBackOffice,
                        zoneAddress0: action.response.beneficiairePourEchange.zoneAdresse0,
                        zoneAddress1: action.response.beneficiairePourEchange.zoneAdresse1,
                        zoneAddress2: action.response.beneficiairePourEchange.zoneAdresse2,
                        zoneAddress3: action.response.beneficiairePourEchange.zoneAdresse3,
                        postalCode: action.response.beneficiairePourEchange.codePostal,
                        town: action.response.beneficiairePourEchange.ville,
                        contracts: action.response.beneficiairePourEchange.contrats.map(c => ({
                            id: c.id,
                            supportType: c.typePriseEnCharge,
                        })),
                        tradesParameters: action.response.beneficiairePourEchange.parametresEchange.map(pe => ({
                            contractId: pe.idContrat,
                            startProductType: pe.typeProduitDepart,
                            finalProductType: pe.typeProduitFinal,
                            enableTrade: pe.autorisationEchange,
                            feeAmount: pe.montantFrais,
                            thresholdAmount: pe.montantPlancher,
                            feeRate: pe.tauxFrais,
                        })),
                    },
                }
            } else if (action.response.beneficiaire) {
                return {
                    firstName: action.response.prenom,
                    lastName: action.response.nom,
                    login: action.response.identifiant,
                    mail: action.response.email,
                    fromBackOffice: action.response.beneficiaire.viaBackOffice,
                    readOnly: action.response.beneficiaire.lectureSeule,
                    beneficiary: {
                        allowAllPaymentModes: action.response.beneficiaire.autoriserToutTypePaiements,
                        civility: getNotDeprecatedCivility(action.response.beneficiaire.civilite),
                        cesuCode: action.response.beneficiaire.codeCESU,
                        birthDate: action.response.beneficiaire.dateNaissance,
                        zoneAddress0: action.response.beneficiaire.zoneAdresse0,
                        zoneAddress1: action.response.beneficiaire.zoneAdresse1,
                        zoneAddress2: action.response.beneficiaire.zoneAdresse2,
                        zoneAddress3: action.response.beneficiaire.zoneAdresse3,
                        postalCode: action.response.beneficiaire.codePostal,
                        town: action.response.beneficiaire.ville,
                        mainPhoneNumber: action.response.beneficiaire.telephonePrincipal,
                        secondaryPhoneNumber: action.response.beneficiaire.telephoneSecondaire,
                        mailOffers: action.response.beneficiaire.offresMail,
                        idCompteSap: action.response.beneficiaire.compteSap,
                    },
                }
            } else
                return {
                    firstName: action.response.prenom,
                    lastName: action.response.nom,
                    login: action.response.identifiant,
                    mail: action.response.email,
                    socialReason: action.response.raisonSociale,
                    clientType: action.response.typeDeClient,
                    clientId: action.response.idClient,
                    clientNumber: action.response.numClient,
                    expandedMode: action.response.modeEtendu,
                    reportableEmployerContribution: action.response.abondementReportable,
                    siret: action.response.siret,
                    segmentId: action.response.idSegment,
                }
        case GET_USER_INFO_FAILURE:
        case DISCONNECT_USER:
            return {}
        default:
            return state
    }
}

export default details

/**
 * Get user details from the store.
 */
export const getDetails = state => state
