import React, {useMemo} from "react"

import {kebabCase} from "lodash"
import PropTypes from "prop-types"
import {useHistory} from "react-router-dom"

import colors from "../../styles/_default.colors.module.scss"
import {getContrastedColor} from "../../utils/colorUtil"
import Link, {LINK_VARIANTS} from "../Link/link"

import "./footerNew.scss"

const Footer = ({backgroundColor, items, textColor}) => {
    const history = useHistory()

    const linkVariant = useMemo(
        () =>
            getContrastedColor(backgroundColor, [colors.footerTextDarkColor, colors.footerTextLightColor]) ===
            colors.footerTextLightColor
                ? LINK_VARIANTS.LIGHT
                : LINK_VARIANTS.DARK,
        [backgroundColor],
    )

    return (
        <div className="footer" style={{backgroundColor}}>
            {items.map(item => (
                <Link
                    className="footer-link"
                    key={`link-${kebabCase(item.label)}`}
                    onClick={item.onClick ? item.onClick : () => history.push(item.route)}
                    variant={linkVariant}
                >
                    {item.label}
                </Link>
            ))}
        </div>
    )
}

Footer.propTypes = {
    backgroundColor: PropTypes.string,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            onClick: PropTypes.func,
            page: PropTypes.string,
            route: PropTypes.string.isRequired,
        }),
    ).isRequired,
    textColor: PropTypes.string, // TODO Retirer la prop textColor quand elle n'est plus utiisée dans l'ensemble des projets
}

Footer.defaultProps = {
    backgroundColor: colors.footerBackgroundColor,
    textColor: null,
}

export default Footer
