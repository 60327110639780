import {CivilityValues, deprecatedCivilityValues} from "../constants/civilityValues"

export const getNotDeprecatedCivility = civility => {
    switch (civility) {
        case CivilityValues.M:
        case CivilityValues.MME:
            return civility
        case deprecatedCivilityValues.MLLE:
            return CivilityValues.MME
        default:
            return null
    }
}
