/**
 * Civility Values which can be used for select
 * @type {{M: string, MME: string}}
 */
export const CivilityValues = {
    M: "M",
    MME: "MME",
}

export const CivilityLabels = {
    M: "Monsieur",
    MME: "Madame",
}

export const civilityRadioOptions = [
    {key: 1, value: CivilityValues.MME, label: CivilityLabels.MME},
    {key: 2, value: CivilityValues.M, label: CivilityLabels.M},
]

export const civilityOptions = [
    {key: 1, value: CivilityValues.M, text: CivilityValues.M},
    {key: 2, value: CivilityValues.MME, text: CivilityValues.MME},
]

export const deprecatedCivilityValues = {
    ...CivilityValues,
    MLLE: "MLLE",
}

export const deprecatedCivilityOptions = [
    ...civilityOptions,
    {key: 3, value: deprecatedCivilityValues.MLLE, text: deprecatedCivilityValues.MLLE},
]
