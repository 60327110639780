import {SAVE_VISUALISATION, RESET_VISUALISATION} from "./visualisationActionTypes"

export const saveVisualisationChoice = (visualisationMode, selectedContract) => ({
    type: SAVE_VISUALISATION,
    visualisationMode,
    selectedContract,
})

export const resetVisualisationChoice = {
    type: RESET_VISUALISATION,
}
